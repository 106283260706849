import { CreateYourVerifimeWallet } from '@/components/CreateYourVerifimeWallet';
import { NAV_ITEM_HOME } from '@/path';
import { api } from '@/utils/api';
import { LANDING_DESCRIPTION, LANDING_TITLE } from '@/utils/messages';
import { clearTrackIdActionNextPage, getTrackIdActionNextPage } from '@/utils/utils';
import {
  PasswordlessInitialSetup,
  useLocalUserCache,
  usePasswordless,
} from '@verifime/cognito-passwordless-auth';
import { Confirmation, Landing } from '@verifime/components';
import { Credential, useFeatureToggle } from '@verifime/utils';
import { useRouter } from 'next/router';
import { useState } from 'react';

export const CREDENTIAL_SETUP_PATH = '/credentialSetup';

export default function CredentialSetup() {
  const { signOut } = usePasswordless();
  const { clearLastSignedInUsers } = useLocalUserCache();
  const { isSetupCredentialAllowSkip } = useFeatureToggle();
  const router = useRouter();
  const [setupMethod, setSetupMethod] = useState<Credential>(Credential.HasCredential);
  const nextPage = getTrackIdActionNextPage();

  const signOutAndRedirect = () => {
    signOut().signedOut.then(() => {
      // When sign out from here means that
      // we only wanna user stay in the email enter page
      // and fill nothing for the email input.
      // Thus we need clear the last signed in users to
      // prevent the page show sign in methods.
      clearLastSignedInUsers();
      router.push('/');
    });
  };

  const proceedToNextPage = () => {
    if (nextPage) {
      clearTrackIdActionNextPage();
      router.push(nextPage);
      return;
    } else {
      // Otherwise redirect to home page
      router.push(`${NAV_ITEM_HOME.path}`);
    }
  };

  const setupComplete = async () => {
    await api.patchV1usermecredentialSetupStatus({
      withCredentials: true,
    });

    proceedToNextPage();
  };

  return (
    <Landing title={LANDING_TITLE} description={LANDING_DESCRIPTION}>
      {setupMethod === Credential.HasCredential && (
        <PasswordlessInitialSetup
          setupComplete={(credentialSetupStatus) => {
            if (credentialSetupStatus === Credential.HasCredential) {
              setupComplete();
            } else {
              setSetupMethod(credentialSetupStatus);
            }
          }}
          noCredentialComponent={({ otpUserDisplayName, goNext }) => (
            <CreateYourVerifimeWallet
              displayName={otpUserDisplayName}
              onCreateWalletClicked={goNext}
              onSignInAsADifferentUserClicked={signOutAndRedirect}
              showSkipOption={isSetupCredentialAllowSkip}
              onSkipOptionConfirmed={proceedToNextPage}
            />
          )}
          hidePasskeySetupOption={true}
        />
      )}
      {setupMethod === Credential.ByAuthenticator && (
        <Confirmation
          title="Confirm Authenticator App Setup"
          confirmButtonText="Complete your identity"
          confirmAction={setupComplete}
          confirmTitle="Authenticator added successfully"
          confirmText="You can now use it to log back into your VerifiMe Wallet next time"
        />
      )}
      {setupMethod === Credential.ByPasskey && (
        <Confirmation
          title="Add your secure device"
          subtitle="Use your fingerprint, faceID or PIN to log into VerifiMe"
          confirmButtonText="Complete your identity"
          confirmAction={setupComplete}
          confirmTitle="Passkey added successfully"
          confirmText="Use your fingerprint, faceID or PIN to log into VerifiMe"
        />
      )}
    </Landing>
  );
}
